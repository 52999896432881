<template>
  <div v-if="$route.params.user_email">
    <v-container>
      <div v-if="!done" class="d-flex flex-column text-center justify-center align-center pa-12">

        <span>{{ $route.params.user_email }}</span>
        <span class="title my-10">Donar-se de baixa del newsletter d'Examenselectivitat</span>

        <v-btn @click="unsubscribe" class="mt-4" color="primary"
               max-width="300"
               depressed large>Donar-se de baixa</v-btn>
      </div>
      <div v-else class="pa-10 text-center">
        <span>S'ha actualitzat correctament</span>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "EmailUnsubscribe",
  data() {
    return {
      done: false
    }
  },
  methods: {
    async unsubscribe() {
      await this.axios.post('/unsubscribe-email/', {
        email: this.$route.params.user_email
      })

      this.done = true
    }
  }
}
</script>

<style scoped>

</style>
